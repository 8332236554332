import { environment } from '@root/environments/environment';

export const AppHeadersKeys = {
  acceptLanguage: 'Accept-Language',
  clientVersion: 'Client-Version',
};

export const getClientVersionHeader = () => {
  return `${environment.versionNumber}_Web`;
};
